.projects--container {
  margin-block: $section-gap;

  .projects {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    row-gap: 5em;
    width: 100%;

    .project {
      height: 400px;
      display: flex;
      justify-content: center;
      column-gap: 30px;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 0 6px 6px #00000010;
      width: 100%;

      .project--image--container {
        @include flex-justify-center();
        overflow: hidden;
        cursor: pointer;
        transition: opacity ease-in-out 0.2s;
        max-width: 700px;
        max-height: 350px;

        .container {
          position: relative;

          img {
            width: 100%;
            height: 100%;
            z-index: 1;
            border-bottom: 3px solid #00000040;
            border-radius: 25px 0;
            // object-fit: contain;
          }

          &::before {
            content: "";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            border-radius: 25px 0;
            z-index: 9;
            transition: 0.3s ease;
            opacity: 0;
          }

          &:hover::before {
            opacity: 0.3;
          }
        }
      }

      .text-container {
        position: relative;
        display: flex;
        align-items: center;
        width: 30%;

        .text {
          color: $darker-white-color;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          position: absolute;
          right: 20px;
          background-color: lighten($color: $body-background-color, $amount: 3);
          padding: 20px;
          width: 550px;
          min-height: 280px;
          border-radius: 15px;
          box-shadow: $shadow;
          z-index: 10;
          row-gap: 10px;

          .used-technologies {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 5px;

            .technologies--container {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              gap: 10px;

              .technology {
                border-radius: 100px;
                width: max-content;
                padding: 5px 10px;
                height: 40px;
                border: 1px solid $primary-pink-color;
                cursor: pointer;
                &:hover {
                  background-color: $primary-pink-color;
                }
              }
            }
          }

          a {
            width: fit-content;
          }

          .project-link {
            color: currentColor;
            font-weight: 700;
            font-size: 32px;
            text-decoration: none;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              transition: transform 0.3s ease;
              inset: 0;
              width: 100%;
              border-bottom: 2px solid currentColor;
              transform-origin: left;
              transform: scaleX(0.2);
            }

            &:hover::after {
              transform: scaleX(0);
            }

            &::before {
              content: "";
              position: absolute;
              transition: transform 0.3s ease;
              inset: 0;
              width: 100%;
              border-bottom: 2px solid currentColor;
              transform-origin: right;
              transform: scaleX(0);
            }

            &:hover::before {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }

        .buttons {
          display: flex;
          justify-content: center;
          width: 100%;

          button {
            width: 70%;
            min-width: 250px;

            a {
              width: 100%;
            }
          }
        }
      }
    }

    .project--reverse {
      flex-direction: row-reverse;

      .text-container {
        .text {
          right: auto;
          // right: -20px;
          left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1124px) {
  .projects--container {
    .project {
      display: flex;
      flex-direction: column !important;
      height: auto !important;

      .project--image--container {
        max-width: unset !important;
      }

      .text-container {
        width: 100% !important;

        .text {
          width: 100% !important;
          position: static !important;
        }
      }
    }

    .project--reverse {
      .text-container {
        order: 2;
      }

      .project--image--container {
        order: 1;
      }
    }

    .projects {
      .project {
        height: 500px;
        grid-template-columns: 600px;
        width: 100%;

        .text {
          margin-top: 25px;

          .buttons {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

.projects--container {
  .projects {
    .yoga-project {
      height: 600px;
      .project--image--container {
        max-height: 600px;
        img {
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (max-width: 824px) {
  .projects--container {
    .projects {
      .project {
        height: 550px;
        grid-template-columns: 1fr;

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: initial;
        }

        .text {
          margin-top: 25px;

          .buttons {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 500px) {
//   .projects--container {
//     .projects {
//       .project-1,
//       .project-2,
//       .project-3 {
//         .text {
//           .project-link {

//           }
//         }
//       }
//     }
//   }
// }
