.client--testimonials--container {
  margin-block: $section-gap;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .client--testimonials {
    padding: 25px 0;
    height: auto;
    color: white;
    align-content: center;
    width: 100%;
    row-gap: 30px;
    max-width: 1200px;
    gap: 10px;
    overflow: hidden;

    .slick-slider {
      position: relative;
      .slick-prev,.slick-next {
        position: absolute;
        z-index: 5;
        bottom: 0;
        top: 42%;
        cursor: pointer;
        width: 32px;
        height: 32px;
        fill: lighten($color: $body-background-color, $amount: 5);
        border-radius: 100%;
        background-color: $darker-white-color;
        border: 1px solid $darker-white-color;
        outline: 3px solid lighten($color: $body-background-color, $amount: 7);
        box-shadow: $shadow;
        margin: 0 100px;
      }
      .slick-next {
        right: 0;
        top: 42%;
      }
    }

    .slick-track {
      display: flex;
      justify-content: center;

      .slick-slide {
        display: flex;
        justify-content: center;
        div {
          display: flex;
          justify-content: center;
        }
      }
    }


    .testimonial {
      @include flex-column-center();
      text-align: center;
      height: 325px;
      box-shadow: $shadow-bottom;
      border-radius: 5px;
      background-color: lighten($color: $body-background-color, $amount: 3);
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      row-gap: 5px;
      width: clamp(350px, 90%, 700px);
      &:hover {
        box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.3);
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        transform-origin: center;
        border-right: 1px solid rgb(110, 110, 115);
        border-left: 1px solid rgb(110, 110, 115);
        width: 100%;
        height: 100%;
        scale: 1 0.2;
        transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      &:hover::before {
        scale: 1 0.6;
        border-color: $light-blue-color;
      }

      .profile-picture {
        img {
          width: 58px;
          height: 58px;
          border-radius: 100%;
        }
      }

      h3 {
        color: $light-blue-color;
        text-transform: capitalize;
        font-size: 26px;
      }

      .text {
        width: 75%;
        font-weight: 300;
        color: $darker-white-color;
      }
    }
  }
}



@media screen and (max-width: 1024px) {
  .client--testimonials {
    justify-content: center !important;
  }

  .slick-slider {
    .slick-arrow {
      margin: 0 50px !important;
    }
  }
}

@media screen and (max-width: 766px) {
  .slick-arrow {
    display: none !important;
  }
}