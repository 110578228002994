header {
  @include flex-justify-center();
  width: 100%;
  transition: all 0.3s ease;
  height: 50px;
  z-index: 5;
  color: $primary-blue-color;
  box-shadow: $shadow-bottom;
  background-color: $body-background-color;
  ul {
    @include flex-justify-center();
    align-items: center;
    width: 75%;
    column-gap: 40px;
    a {
      text-decoration: none;
    }
    li {
      animation: sizeUp 0.25s ease;
      padding: 0;
      height: 99%;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      width: fit-content;
      list-style: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;
      color: $darker-white-color;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        transform: scaleX(0);
        transition: 0.2s ease;
        border-bottom: 3px solid $primary-blue-color;
        transform-origin: center;
        text-align: center;
      }
      &:hover {
        color: $primary-blue-color;
      }
      &:hover::before {
        transform: scaleX(1);
        animation-timing-function: ease-in-out;
      }
    }
  }
}

.hamburger-icon {
  display: none;
}

@media screen and (max-width: 1024px) {
  header {
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    ul {
      width: 100%;
      display: none;
      li {
        width: fit-content;
        padding: 10px 0;
      }
    }

    .hamburger-icon {
      display: flex;
      min-width: 32px;
      min-height: 32px;
      height: 32px;
      width: 32px;
      margin: 8px;
      cursor: pointer;
    }
  }
}

.dropdown-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes sizeUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180);
  }
}

