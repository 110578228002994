.services--container {
  margin-block: $section-gap;

  .services {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    flex-wrap: wrap;
    height: auto;
    column-gap: 40px;
    color: white;
    box-shadow: 0 0 12px 0px #00000030;
    align-content: center;
    background-color: $dark-blue-color;

    .icon {
      @include flex-justify-center();
      margin-bottom: 10px;
      color: $darker-white-color
    }

    .service--name {
      width: 100%;
      text-align: center;
      color: $darker-white-color
    }

    .service--title {
      font-size: 36px;
      text-align: center;
      color: $darker-white-color
    }

    .text {
      @include flex-justify-center();
      text-align: center;
      word-break: keep-all;
      color: $darker-white-color;
      max-width: 700px;
      margin: 0 auto;
     
    }

    ul > li {
      list-style-type: none
    }

    .service {
      @include flex-justify-center();
      flex-direction: column;
      margin-top: 25px;
      margin-bottom: 50px;
      width: 100%;
    }
  }
}