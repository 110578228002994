.contact-form-items--container {
  // margin-block: $section-gap--small;
  @include flex-center;
  column-gap: 25px;
  width: 100%;
  background-color: #00000060;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 100;

  .contact-inputs {
    margin: 10px 0;
    @include flex-column-center;
    max-width: 550px;
    width: 90%;
    height: 450px;
    background-color: $lighter-black;
    padding: 50px;
    border-radius: 15px;
    box-shadow: $shadow;
    row-gap: 10px;
    overflow: auto;

    .input-col {
      display: flex;
      width: 100%;
      column-gap: 10px;
    }
    .text-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: white;
    }
    .input--container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;

      .input--placeholder {
        color: gray;
        position: absolute;
        left: 0;
        top: 6.8px;
        width: fit-content;
        height: fit-content;
        margin-left: 10px;
        background-color: $lighter-black;
        transition: 0.2s ease;
        pointer-events: none;
      }

      input {
        width: 100%;
        height: 40px;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0 20px;
        color: $darker-white-color;
        font-weight: 300;
        border: 1px $primary-blue-color solid;
        position: relative;
        font-size: 16px;

        &:focus+.input--placeholder {
          font-size: 12px;
          top: calc(0px - 10px);
          padding: 0 10px;
        }

        &:valid+.input--placeholder {
          font-size: 12px;
          top: calc(0px - 10px);
          padding: 0 10px;
        }
      }

      textarea {
        width: 100%;
        height: 100px;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 5px 20px;
        color: $darker-white-color;
        font-weight: 300;
        border: 1px $primary-blue-color solid;
        position: relative;
        font-size: 16px;
        resize: vertical;
        min-height: 100px;

        &:focus+.input--placeholder {
          font-size: 12px;
          padding: 0 10px;
          top: calc(0px - 10px);
        }

        &:valid+.input--placeholder {
          font-size: 12px;
          padding: 0 10px;
          top: calc(0px - 10px);
        }
      }
    }

    button {
      width: clamp(250px, 100%, 300px);
    }
  }
}


@media screen and (max-width:824px) {
  .image--container {
    margin-bottom: 25px;
    height: 300px !important;
  }
}