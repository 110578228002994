@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
header {
  display: flex;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease;
  height: 50px;
  z-index: 5;
  color: #42426b;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  background-color: #292939;
}
header ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  -moz-column-gap: 40px;
       column-gap: 40px;
}
header ul a {
  text-decoration: none;
}
header ul li {
  animation: sizeUp 0.25s ease;
  padding: 0;
  height: 99%;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  color: #e6e6e6;
}
header ul li::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  transform: scaleX(0);
  transition: 0.2s ease;
  border-bottom: 3px solid #42426b;
  transform-origin: center;
  text-align: center;
}
header ul li:hover {
  color: #42426b;
}
header ul li:hover::before {
  transform: scaleX(1);
  animation-timing-function: ease-in-out;
}

.hamburger-icon {
  display: none;
}

@media screen and (max-width: 1024px) {
  header {
    flex-direction: column;
    align-items: flex-end;
    height: auto;
  }
  header ul {
    width: 100%;
    display: none;
  }
  header ul li {
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 0;
  }
  header .hamburger-icon {
    display: flex;
    min-width: 32px;
    min-height: 32px;
    height: 32px;
    width: 32px;
    margin: 8px;
    cursor: pointer;
  }
}
.dropdown-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes sizeUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180);
  }
}
.profile {
  display: grid;
  align-content: center;
  height: auto;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.profile .background-design {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
.profile .background-design::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, #292939 100%);
}
.profile .background-design img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.profile .profile__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  animation: fadeUp steps(1) ease;
}
.profile .profile__text-container h1 {
  padding: 0;
  font-size: 80px;
}
.profile .profile__text-container .skill-text {
  white-space: pre-wrap;
  justify-content: center;
  color: #de03c8;
  text-transform: capitalize;
  font-weight: 700;
}
.profile .profile__text-container p {
  text-align: center;
  width: 500px;
  margin: 20px 0;
  color: #e6e6e6;
}
.profile .profile__text-container .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.profile .profile__text-container .buttons .call-to-action-btn {
  width: 200px;
  height: 50px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile .profile__text-container .buttons .download-cv-btn {
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.profile .profile__text-container .buttons .download-cv-btn::before {
  width: 120%;
  left: -130%;
}
.profile .profile__text-container .buttons .download-cv-btn:hover::before {
  left: -10%;
}
.profile .profile__text-container .buttons .linkedin-btn {
  width: 125px;
}
.profile .profile__text-container .buttons .linkedin-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.profile .profile__text-container .buttons .linkedin-btn::before {
  width: 120%;
  left: -130%;
}
.profile .profile__text-container .buttons .linkedin-btn:hover::before {
  left: -10%;
}

.arrow--right {
  transition: 0.2s ease;
  position: relative;
  left: 7px;
}

.arrow--right--move {
  left: 12px;
}

@keyframes LetterSpace {
  from {
    opacity: 0;
    letter-spacing: 10px;
  }
  to {
    opacity: 1;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 1000px) {
  .profile .profile__text-container h1 {
    font-size: 60px;
  }
  .profile .profile__text-container p {
    width: 90% !important;
  }
}
@media screen and (max-width: 550px) {
  .profile .profile__text-container h1 {
    font-size: 42px;
    text-align: left;
  }
  .profile .profile__text-container p {
    text-align: left;
    min-width: 100%;
  }
  .profile .profile__text-container button {
    width: 300px;
  }
}
.projects--container {
  margin-block: 50px;
}
.projects--container .projects {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  row-gap: 5em;
  width: 100%;
}
.projects--container .projects .project {
  height: 400px;
  display: flex;
  justify-content: center;
  -moz-column-gap: 30px;
       column-gap: 30px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.062745098);
  width: 100%;
}
.projects--container .projects .project .project--image--container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  transition: opacity ease-in-out 0.2s;
  max-width: 700px;
  max-height: 350px;
}
.projects--container .projects .project .project--image--container .container {
  position: relative;
}
.projects--container .projects .project .project--image--container .container img {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2509803922);
  border-radius: 25px 0;
}
.projects--container .projects .project .project--image--container .container::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 25px 0;
  z-index: 9;
  transition: 0.3s ease;
  opacity: 0;
}
.projects--container .projects .project .project--image--container .container:hover::before {
  opacity: 0.3;
}
.projects--container .projects .project .text-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
}
.projects--container .projects .project .text-container .text {
  color: #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  right: 20px;
  background-color: #2f2f42;
  padding: 20px;
  width: 550px;
  min-height: 280px;
  border-radius: 15px;
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.3);
  z-index: 10;
  row-gap: 10px;
}
.projects--container .projects .project .text-container .text .used-technologies {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.projects--container .projects .project .text-container .text .used-technologies .technologies--container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.projects--container .projects .project .text-container .text .used-technologies .technologies--container .technology {
  border-radius: 100px;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
  height: 40px;
  border: 1px solid #de03c8;
  cursor: pointer;
}
.projects--container .projects .project .text-container .text .used-technologies .technologies--container .technology:hover {
  background-color: #de03c8;
}
.projects--container .projects .project .text-container .text a {
  width: -moz-fit-content;
  width: fit-content;
}
.projects--container .projects .project .text-container .text .project-link {
  color: currentColor;
  font-weight: 700;
  font-size: 32px;
  text-decoration: none;
  position: relative;
}
.projects--container .projects .project .text-container .text .project-link::after {
  content: "";
  position: absolute;
  transition: transform 0.3s ease;
  inset: 0;
  width: 100%;
  border-bottom: 2px solid currentColor;
  transform-origin: left;
  transform: scaleX(0.2);
}
.projects--container .projects .project .text-container .text .project-link:hover::after {
  transform: scaleX(0);
}
.projects--container .projects .project .text-container .text .project-link::before {
  content: "";
  position: absolute;
  transition: transform 0.3s ease;
  inset: 0;
  width: 100%;
  border-bottom: 2px solid currentColor;
  transform-origin: right;
  transform: scaleX(0);
}
.projects--container .projects .project .text-container .text .project-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
.projects--container .projects .project .text-container .buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}
.projects--container .projects .project .text-container .buttons button {
  width: 70%;
  min-width: 250px;
}
.projects--container .projects .project .text-container .buttons button a {
  width: 100%;
}
.projects--container .projects .project--reverse {
  flex-direction: row-reverse;
}
.projects--container .projects .project--reverse .text-container .text {
  right: auto;
  left: 20px;
}

@media screen and (max-width: 1124px) {
  .projects--container .project {
    display: flex;
    flex-direction: column !important;
    height: auto !important;
  }
  .projects--container .project .project--image--container {
    max-width: unset !important;
  }
  .projects--container .project .text-container {
    width: 100% !important;
  }
  .projects--container .project .text-container .text {
    width: 100% !important;
    position: static !important;
  }
  .projects--container .project--reverse .text-container {
    order: 2;
  }
  .projects--container .project--reverse .project--image--container {
    order: 1;
  }
  .projects--container .projects .project {
    height: 500px;
    grid-template-columns: 600px;
    width: 100%;
  }
  .projects--container .projects .project .text {
    margin-top: 25px;
  }
  .projects--container .projects .project .text .buttons {
    margin-top: 25px;
  }
}
.projects--container .projects .yoga-project {
  height: 600px;
}
.projects--container .projects .yoga-project .project--image--container {
  max-height: 600px;
}
.projects--container .projects .yoga-project .project--image--container img {
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (max-width: 824px) {
  .projects--container .projects .project {
    height: 550px;
    grid-template-columns: 1fr;
  }
  .projects--container .projects .project img {
    width: 100%;
    height: 100%;
    aspect-ratio: initial;
  }
  .projects--container .projects .project .text {
    margin-top: 25px;
  }
  .projects--container .projects .project .text .buttons {
    margin-top: 25px;
  }
}
.services--container {
  margin-block: 50px;
}
.services--container .services {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  flex-wrap: wrap;
  height: auto;
  -moz-column-gap: 40px;
       column-gap: 40px;
  color: white;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.1882352941);
  align-content: center;
  background-color: #42426b;
}
.services--container .services .icon {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #e6e6e6;
}
.services--container .services .service--name {
  width: 100%;
  text-align: center;
  color: #e6e6e6;
}
.services--container .services .service--title {
  font-size: 36px;
  text-align: center;
  color: #e6e6e6;
}
.services--container .services .text {
  display: flex;
  justify-content: center;
  text-align: center;
  word-break: keep-all;
  color: #e6e6e6;
  max-width: 700px;
  margin: 0 auto;
}
.services--container .services ul > li {
  list-style-type: none;
}
.services--container .services .service {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 50px;
  width: 100%;
}

.client--testimonials--container {
  margin-block: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.client--testimonials--container .client--testimonials {
  padding: 25px 0;
  height: auto;
  color: white;
  align-content: center;
  width: 100%;
  row-gap: 30px;
  max-width: 1200px;
  gap: 10px;
  overflow: hidden;
}
.client--testimonials--container .client--testimonials .slick-slider {
  position: relative;
}
.client--testimonials--container .client--testimonials .slick-slider .slick-prev, .client--testimonials--container .client--testimonials .slick-slider .slick-next {
  position: absolute;
  z-index: 5;
  bottom: 0;
  top: 42%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  fill: #343448;
  border-radius: 100%;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  outline: 3px solid #38384e;
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.3);
  margin: 0 100px;
}
.client--testimonials--container .client--testimonials .slick-slider .slick-next {
  right: 0;
  top: 42%;
}
.client--testimonials--container .client--testimonials .slick-track {
  display: flex;
  justify-content: center;
}
.client--testimonials--container .client--testimonials .slick-track .slick-slide {
  display: flex;
  justify-content: center;
}
.client--testimonials--container .client--testimonials .slick-track .slick-slide div {
  display: flex;
  justify-content: center;
}
.client--testimonials--container .client--testimonials .testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 325px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #2f2f42;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  row-gap: 5px;
  width: clamp(350px, 90%, 700px);
}
.client--testimonials--container .client--testimonials .testimonial:hover {
  box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.3);
}
.client--testimonials--container .client--testimonials .testimonial::before {
  content: "";
  position: absolute;
  inset: 0;
  transform-origin: center;
  border-right: 1px solid rgb(110, 110, 115);
  border-left: 1px solid rgb(110, 110, 115);
  width: 100%;
  height: 100%;
  scale: 1 0.2;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.client--testimonials--container .client--testimonials .testimonial:hover::before {
  scale: 1 0.6;
  border-color: #6368ff;
}
.client--testimonials--container .client--testimonials .testimonial .profile-picture img {
  width: 58px;
  height: 58px;
  border-radius: 100%;
}
.client--testimonials--container .client--testimonials .testimonial h3 {
  color: #6368ff;
  text-transform: capitalize;
  font-size: 26px;
}
.client--testimonials--container .client--testimonials .testimonial .text {
  width: 75%;
  font-weight: 300;
  color: #e6e6e6;
}

@media screen and (max-width: 1024px) {
  .client--testimonials {
    justify-content: center !important;
  }
  .slick-slider .slick-arrow {
    margin: 0 50px !important;
  }
}
@media screen and (max-width: 766px) {
  .slick-arrow {
    display: none !important;
  }
}
.contact-form-items--container {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 25px;
       column-gap: 25px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3764705882);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 100;
}
.contact-form-items--container .contact-inputs {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  width: 90%;
  height: 450px;
  background-color: #232323;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.3);
  row-gap: 10px;
  overflow: auto;
}
.contact-form-items--container .contact-inputs .input-col {
  display: flex;
  width: 100%;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.contact-form-items--container .contact-inputs .text-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
}
.contact-form-items--container .contact-inputs .input--container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.contact-form-items--container .contact-inputs .input--container .input--placeholder {
  color: gray;
  position: absolute;
  left: 0;
  top: 6.8px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 10px;
  background-color: #232323;
  transition: 0.2s ease;
  pointer-events: none;
}
.contact-form-items--container .contact-inputs .input--container input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0 20px;
  color: #e6e6e6;
  font-weight: 300;
  border: 1px #42426b solid;
  position: relative;
  font-size: 16px;
}
.contact-form-items--container .contact-inputs .input--container input:focus + .input--placeholder {
  font-size: 12px;
  top: -10px;
  padding: 0 10px;
}
.contact-form-items--container .contact-inputs .input--container input:valid + .input--placeholder {
  font-size: 12px;
  top: -10px;
  padding: 0 10px;
}
.contact-form-items--container .contact-inputs .input--container textarea {
  width: 100%;
  height: 100px;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 5px 20px;
  color: #e6e6e6;
  font-weight: 300;
  border: 1px #42426b solid;
  position: relative;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
}
.contact-form-items--container .contact-inputs .input--container textarea:focus + .input--placeholder {
  font-size: 12px;
  padding: 0 10px;
  top: -10px;
}
.contact-form-items--container .contact-inputs .input--container textarea:valid + .input--placeholder {
  font-size: 12px;
  padding: 0 10px;
  top: -10px;
}
.contact-form-items--container .contact-inputs button {
  width: clamp(250px, 100%, 300px);
}

@media screen and (max-width: 824px) {
  .image--container {
    margin-bottom: 25px;
    height: 300px !important;
  }
}
::-webkit-scrollbar {
  width: 15px;
}

html {
  scroll-behavior: smooth;
  scrollbar-color: #42426b #292939;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #42426b;
}

*, ::before, ::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Outfit", sans-serif;
  line-height: 1.65em;
}

::-moz-selection {
  background-color: #e6e6e6;
  color: #42426b !important;
}

::selection {
  background-color: #e6e6e6;
  color: #42426b !important;
}

.box-content {
  margin: 0 auto;
  width: 85%;
}

@media screen and (min-width: 1900px) {
  .box-content {
    width: 1200px;
    margin: 0 auto;
  }
  .profile {
    height: 700px;
    min-height: 700px;
  }
}
@media screen and (max-width: 600px) {
  .box-content {
    width: 95%;
  }
}
body {
  background-color: #292939;
}

h1 {
  text-align: center;
  color: #e6e6e6;
  padding: 30px 0;
  font-weight: 600;
  font-size: 40px;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
}

button {
  width: 200px;
  height: 50px;
  background-color: transparent;
  border: 1px #42426b solid;
  position: relative;
  overflow: hidden;
  color: white;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  isolation: isolate;
  font-family: "Outfit", sans-serif;
}
button::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  left: -115%;
  width: 110%;
  height: 100%;
  background-color: #42426b;
  transition: 0.3s ease;
  transform: skew(-20deg);
  transform-origin: center;
}
button:hover::before {
  left: -5%;
}
button a {
  color: white;
  text-decoration: none;
  z-index: 3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.white-text {
  color: #e6e6e6;
}/*# sourceMappingURL=index.css.map */