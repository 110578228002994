.profile {
  display: grid;
  align-content: center;
  height: auto;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  .background-design {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      background: linear-gradient(to bottom, #ffffff00 80%, $body-background-color 100%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .profile__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    animation: fadeUp steps(1) ease;

    h1 {
      padding: 0;
      font-size: 80px;
    }

    .skill-text {
      white-space: pre-wrap;
      justify-content: center;
      color: $primary-pink-color;
      text-transform: capitalize;
      font-weight: 700;
    }

    p {
      text-align: center;
      width: 500px;
      margin: 20px 0;
      color: $darker-white-color;
    }
    .buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .call-to-action-btn {
        width: 200px;
        height: 50px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .download-cv-btn {
        width: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        &::before {
          width: 120%;
          left: -130%;
        }
        &:hover::before {
          left: -10%;
        }
      }
      .linkedin-btn {
        width: 125px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
        }
        &::before {
          width: 120%;
          left: -130%;
        }
        &:hover::before {
          left: -10%;
        }
      }
    }
  }
}
.arrow--right {
  transition: 0.2s ease;
  position: relative;
  left: 7px;
}
.arrow--right--move {
  left: 12px;
}

@keyframes LetterSpace {
  from {
    opacity: 0;
    letter-spacing: 10px;
  }

  to {
    opacity: 1;
    letter-spacing: normal;
  }
}

@media screen and (max-width: 1000px) {
  .profile {
    .profile__text-container {
      h1 {
        font-size: 60px;
      }

      p {
        width: 90% !important;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .profile {
    .profile__text-container {
      h1 {
        font-size: 42px;
        text-align: left;
      }

      p {
        text-align: left;
        min-width: 100%;
      }

      button {
        width: 300px;
      }
    }
  }
}